<template>
  <div class="article">
    <div class="head">
      <h5>현황판 색 설정</h5>
    </div>
    <div class="form">
      <form class="status_color_form">
        <ul>
          <li>
            <div>
              <input
                id="statusThBgColorUnsuk"
                type="color"
                v-model="status_background_pick"
              />
            </div>
            <label for="statusThBgColorUnsuk">전체 배경</label>
          </li>
          <li>
            <div>
              <input
                id="statusThBdColorUnsuk"
                type="color"
                v-model="status_border_pick"
              />
            </div>
            <label for="statusThBdColorUnsuk">테이블 외부 테두리</label>
          </li>
          <li>
            <div>
              <input
                id="statusThBdiColorUnsuk"
                type="color"
                v-model="status_border_inner"
              />
            </div>
            <label for="statusThBdiColorUnsuk">테이블 내부 테두리</label>
          </li>
          <li>
            <div>
              <input
                id="statusThCrtColorUnsuk"
                type="color"
                v-model="status_background_temp"
              />
            </div>
            <label for="statusThCrtColorUnsuk">테이블 제목 배경</label>
          </li>
          <li>
            <div>
              <input
                id="statusThCrtFontUnsuk"
                type="color"
                v-model="status_color_temp"
              />
            </div>
            <label for="statusThCrtFontUnsuk"> 테이블 제목 글자</label>
          </li>
        </ul>
        <ul>
          <li>
            <div>
              <input
                id="statusThTableColorUnsuk"
                type="color"
                v-model="status_background_table"
              />
            </div>
            <label for="statusThTableColorUnsuk">테이블 내용 배경 </label>
          </li>
          <li>
            <div>
              <input
                id="statusThDetailColorUnsuk"
                type="color"
                v-model="status_color_detail"
              />
            </div>
            <label for="statusThDetailColorUnsuk">테이블 내용 글자</label>
          </li>
          <li>
            <div>
              <input
                id="statusThBtnColorUnsuk"
                type="color"
                v-model="status_background_button"
              />
            </div>
            <label for="statusThBtnColorUnsuk">전체화면&재조회 버튼 배경</label>
          </li>
          <li>
            <div>
              <input
                id="statusThBtnFontUnsuk"
                type="color"
                v-model="status_color_button"
              />
            </div>
            <label for="statusThBtnFontUnsuk">전체화면&재조회 버튼 글자</label>
          </li>
          <li>
            <div>
              <input
                id="statusThSlideColorUnsuk"
                type="color"
                v-model="status_background_slide_btn"
              />
            </div>
            <label for="statusThSlideColorUnsuk">이전/다음 버튼 배경</label>
          </li>
          <li>
            <div>
              <input
                id="statusThSlideIconUnsuk"
                type="color"
                v-model="status_color_slide_btn"
              />
            </div>
            <label for="statusThSlideIconUnsuk">이전/다음 버튼 글자</label>
          </li>
        </ul>
        <div class="btn_wrap">
          <button class="btn_sub2" @click.prevent="showModal($event)">
            초기화
          </button>
          <button class="btn_sub2" @click.prevent="submitFormColor">
            저장
          </button>
        </div>
      </form>
    </div>
    <two-button-modal
      :modal_title="my_modal_title"
      :modal_content="my_modal_content"
      :isModalOpen="my_isModalOpen"
      @onclose="CloseModal"
      @OnYesClick="removeColor()"
    >
    </two-button-modal>
  </div>
</template>

<script>
import ModalMixin from '@/mixins/modal';
import TwoButtonModal from '@/layouts/components/TwoButtonModal';
export default {
  mixins: [ModalMixin],
  components: {
    TwoButtonModal,
  },
  data() {
    return {
      //실시간 온습도 현황 Color
      status_background_pick: '#1e2242',
      status_border_pick: '#1e2242',
      status_border_inner: '#7f84aa',
      status_background_temp: '#2f3664',
      status_color_temp: '#ffffff',
      status_background_table: '#3b427a',
      status_color_detail: '#ffffff',
      status_background_button: '#3b427a',
      status_color_button: '#ffffff',
      status_background_slide_btn: '#3b427a',
      status_color_slide_btn: '#ffffff',

      //reset modal
      my_modal_title: '',
      my_modal_content: '',
      my_modal_index: -1,
      my_isModalOpen: false,
    };
  },
  mounted() {
    this.status_background_pick = localStorage.getItem(
      'statusArticleColorUnsuk',
    );
    this.status_border_pick = localStorage.getItem('statusMesborTblUnsuk');
    this.status_border_inner = localStorage.getItem('statusMesboriTblUnsuk');
    this.status_background_temp = localStorage.getItem(
      'statusTempbgColorUnsuk',
    );
    this.status_color_temp = localStorage.getItem('statusTempFontUnsuk');

    this.status_background_table = localStorage.getItem(
      'statusTableColorUnsuk',
    );
    this.status_color_detail = localStorage.getItem('statusDetailFontUnsuk');
    this.status_background_button = localStorage.getItem('statusBtnColorUnsuk');
    this.status_color_button = localStorage.getItem('statusBtnFontColorUnsuk');
    this.status_background_slide_btn = localStorage.getItem(
      'statusSlideBtnColorUnsuk',
    );
    this.status_color_slide_btn = localStorage.getItem(
      'statusSlideBtniColorUnsuk',
    );
  },

  methods: {
    showModal() {
      this.my_modal_title = '알림';
      this.my_modal_content = '정말로 초기화하시겠습니까?';
      this.my_isModalOpen = true;
    },
    CloseModal() {
      this.my_modal_index = -1;
      this.my_modal_title = '';
      this.my_modal_content = '';
      this.my_isModalOpen = false;
    },
    submitFormColor() {
      localStorage.setItem(
        'statusArticleColorUnsuk',
        this.status_background_pick,
      );
      localStorage.setItem('statusMesborTblUnsuk', this.status_border_pick);
      localStorage.setItem('statusMesboriTblUnsuk', this.status_border_inner);
      localStorage.setItem(
        'statusTempbgColorUnsuk',
        this.status_background_temp,
      );
      localStorage.setItem('statusTempFontUnsuk', this.status_color_temp);
      localStorage.setItem(
        'statusDivColorUnsuk',
        this.status_background_division,
      );
      localStorage.setItem('statusDivFontUnsuk', this.status_color_division);
      localStorage.setItem(
        'statusTableColorUnsuk',
        this.status_background_table,
      );
      localStorage.setItem('statusDetailFontUnsuk', this.status_color_detail);
      localStorage.setItem(
        'statusDetailTempFontUnsuk',
        this.status_color_detail_temp,
      );
      localStorage.setItem(
        'statusBtnColorUnsuk',
        this.status_background_button,
      );
      localStorage.setItem('statusBtnFontColorUnsuk', this.status_color_button);
      localStorage.setItem(
        'statusSlideBtnColorUnsuk',
        this.status_background_slide_btn,
      );
      localStorage.setItem(
        'statusSlideBtniColorUnsuk',
        this.status_color_slide_btn,
      );

      this.openOneButtonModal('저장 성공', '성공적으로 저장하였습니다.');
    },
    removeColor() {
      localStorage.setItem('statusArticleColorUnsuk', '#1e2242'); //배경
      localStorage.setItem('statusMesborTblUnsuk', '#1e2242'); //테두리외부
      localStorage.setItem('statusMesboriTblUnsuk', '#7f84aa'); //테두리내부
      localStorage.setItem('statusTempbgColorUnsuk', '#2f3664'); //온습도현황 칼라
      localStorage.setItem('statusTempFontUnsuk', '#ffffff'); //온습도현황 폰트
      localStorage.setItem('statusTableColorUnsuk', '#3b427a'); //테이블 칼라
      localStorage.setItem('statusDetailFontUnsuk', '#ffffff'); //온습도폰트
      localStorage.setItem('statusBtnColorUnsuk', '#3b427a'); //버튼색
      localStorage.setItem('statusBtnFontColorUnsuk', '#ffffff'); // 버튼폰트
      localStorage.setItem('statusSlideBtnColorUnsuk', '#3b427a'); //슬라이드 칼라
      localStorage.setItem('statusSlideBtniColorUnsuk', '#ffffff'); //슬라이드 폰트

      this.status_background_pick = '#1e2242';
      this.status_border_pick = '#1e2242';
      this.status_border_inner = '#7f84aa';
      this.status_background_temp = '#2f3664';
      this.status_color_temp = '#ffffff';
      this.status_background_table = '#3b427a';
      this.status_color_detail = '#ffffff';
      this.status_background_button = '#3b427a';
      this.status_color_button = '#ffffff';
      this.status_background_slide_btn = '#3b427a';
      this.status_color_slide_btn = '#ffffff';
      this.CloseModal();
    },
  },
  async created() {
    if (localStorage.getItem('statusArticleColorUnsuk') == undefined) {
      localStorage.setItem(
        'statusArticleColorUnsuk',
        this.status_background_pick,
      );
    }
    if (localStorage.getItem('statusMesborTblUnsuk') == undefined) {
      localStorage.setItem('statusMesborTblUnsuk', this.status_border_pick);
    }
    if (localStorage.getItem('statusMesboriTblUnsuk') == undefined) {
      localStorage.setItem('statusMesboriTblUnsuk', this.status_border_inner);
    }
    if (localStorage.getItem('statusTempbgColorUnsuk') == undefined) {
      localStorage.setItem(
        'statusTempbgColorUnsuk',
        this.status_background_temp,
      );
    }
    if (localStorage.getItem('statusTempFontUnsuk') == undefined) {
      localStorage.setItem('statusTempFontUnsuk', this.status_color_temp);
    }
    if (localStorage.getItem('statusTableColorUnsuk') == undefined) {
      localStorage.setItem(
        'statusTableColorUnsuk',
        this.status_background_table,
      );
    }
    if (localStorage.getItem('statusDetailFontUnsuk') == undefined) {
      localStorage.setItem('statusDetailFontUnsuk', this.status_color_detail);
    }
    if (localStorage.getItem('statusBtnColorUnsuk') == undefined) {
      localStorage.setItem(
        'statusBtnColorUnsuk',
        this.status_background_button,
      );
    }
    if (localStorage.getItem('statusBtnFontColorUnsuk') == undefined) {
      localStorage.setItem('statusBtnFontColorUnsuk', this.status_color_button);
    }
    if (localStorage.getItem('statusSlideBtnColorUnsuk') == undefined) {
      localStorage.setItem(
        'statusSlideBtnColorUnsuk',
        this.status_background_slide_btn,
      );
    }
    if (localStorage.getItem('statusSlideBtniColorUnsuk') == undefined) {
      localStorage.setItem(
        'statusSlideBtniColorUnsuk',
        this.status_color_slide_btn,
      );
    }
  },
};
</script>

<style lang="scss" scoped></style>
