<template>
  <div
    id="contents"
    class="status_board_unsuk"
    :class="{
      plc_monitor: tabIndex != 4,
      work_status: tabIndex == 1,
      color_setting: tabIndex == 4,
    }"
  >
    <div class="contents_head">
      <h2>{{ $getPageTitle($route.path) }}</h2>
      <div class="aside" v-if="$screen.width < 1280">
        <aside-selectric
          :id="'tabletAsideSelectric'"
          :watch="tabIndex"
          :commit="'setOpenTabIndexToStatusMonitorPage'"
          :options="[
            { title: '입고 현황', name: 'input_status' },
            { title: '작업 현황', name: 'work_status' },
            { title: '생산 현황', name: 'production_status' },
            { title: '출고 현황', name: 'output_status' },
            { title: '현황판 색 설정', name: 'color_setting' },
          ]"
        >
        </aside-selectric>
      </div>
      <div class="management_btn_group">
        <div>
          <span
            class="add_favorite"
            @click="AddFavorite"
            :class="{ on: isFavorOn }"
            >즐겨찾기 추가</span
          >
          <!-- <button class="btn_admin">저장</button> -->
          <button class="btn_admin" @click="CloseThisPage()">닫기</button>
        </div>
      </div>
    </div>
    <div class="section">
      <div class="aside" v-if="$screen.width >= 1280">
        <ul>
          <li :class="{ active: tabIndex == 0 }">
            <a @click="SetOpenTabIndex(0)">입고 현황</a>
          </li>
          <li :class="{ active: tabIndex == 1 }">
            <a @click="SetOpenTabIndex(1)">작업 현황</a>
          </li>
          <li :class="{ active: tabIndex == 2 }">
            <a @click="SetOpenTabIndex(2)">생산 현황</a>
          </li>
          <li :class="{ active: tabIndex == 3 }">
            <a @click="SetOpenTabIndex(3)">출고 현황</a>
          </li>
          <li :class="{ active: tabIndex == 4 }">
            <a @click="SetOpenTabIndex(4)">현황판 색 설정</a>
          </li>
        </ul>
      </div>
      <input-status-form v-if="tabIndex == 0"></input-status-form>
      <work-status-form v-if="tabIndex == 1"></work-status-form>
      <production-status-form v-if="tabIndex == 2"></production-status-form>
      <output-status-form v-if="tabIndex == 3"></output-status-form>
      <status-color-picker-form v-if="tabIndex == 4"></status-color-picker-form>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex';
import AsideSelectric from '@/layouts/components/AsideSelectric';
import InputStatusForm from '@/views/forms/Custom/Monitor/8/InputStatusForm';
import WorkStatusForm from '@/views/forms/Custom/Monitor/8/WorkStatusForm';
import ProductionStatusForm from '@/views/forms/Custom/Monitor/8/ProductionStatusForm';
import OutputStatusForm from '@/views/forms/Custom/Monitor/8/OutputStatusForm';
import StatusColorPickerForm from '@/views/forms/Custom/Monitor/8/StatusColorPickerForm';
import ClosePageMixin from '@/mixins/closePage';
import FavoriteMixin from '@/mixins/favorite';

export default {
  mixins: [ClosePageMixin, FavoriteMixin],
  components: {
    AsideSelectric,
    InputStatusForm,
    WorkStatusForm,
    ProductionStatusForm,
    OutputStatusForm,
    StatusColorPickerForm,
  },
  data() {
    return {
      swiper: null,
    };
  },
  computed: {
    ...mapGetters({
      tabIndex: 'getOpenTabIndexFromStatusMonitorPage',
    }),
  },
  methods: {
    ...mapMutations({
      SetOpenTabIndex: 'setOpenTabIndexToStatusMonitorPage',
    }),
  },
  async created() {
    if (this.$route.meta.menu_init == true) {
      this.$store.commit('InitStatusMonitorPage');
      this.$route.meta.menu_init = false;
    }
  },
};
</script>

<style lang="scss">
@import 'unsuk_monitoring';
</style>
