<template>
  <div
    class="article"
    :class="{ active: fullScreen == true }"
    :style="`background-color: ${statusArticleColorUnsuk}`"
  >
    <div class="tbl_wrap">
      <div class="tbl_option">
        <h5 class="title" :style="`color: ${statusTempFontUnsuk};`">
          입고 현황
        </h5>
        <button
          class="btn_sub1 refresh_btn"
          :style="
            `background-color: ${statusBtnColorUnsuk} color: ${statusBtnFontColorUnsuk}`
          "
          @click="refreshData"
        >
          재조회
        </button>
        <button
          class="btn_sub1"
          @click="fullScreenFn"
          :style="
            `background-color: ${statusBtnColorUnsuk} color: ${statusBtnFontColorUnsuk}`
          "
        >
          {{ fullScreen ? '닫기' : '전체화면' }}
        </button>
      </div>
      <swiper
        ref="mySwiperRef"
        class="swiper-container"
        :options="swiperOption"
        :style="`border: 2px solid ${statusMesborTblUnsuk}`"
      >
        <swiper-slide
          class="mes_tbl_wrap swiper-slide"
          v-for="(items, index) in filteredTodayMaterialStatus"
          :key="index"
        >
          <table class="mes_tbl">
            <thead>
              <tr>
                <th
                  :style="
                    `border-bottom: 2px solid ${statusMesboriTblUnsuk}; background-color: ${statusTempbgColorUnsuk}; color: ${statusTempFontUnsuk};`
                  "
                >
                  입고일자
                </th>
                <th
                  :style="
                    `border-left: 2px solid ${statusMesboriTblUnsuk}; border-bottom: 2px solid ${statusMesboriTblUnsuk}; background-color: ${statusTempbgColorUnsuk}; color: ${statusTempFontUnsuk};`
                  "
                >
                  거래처
                </th>
                <th
                  :style="
                    `border-left: 2px solid ${statusMesboriTblUnsuk}; border-bottom: 2px solid ${statusMesboriTblUnsuk}; background-color: ${statusTempbgColorUnsuk}; color: ${statusTempFontUnsuk};`
                  "
                >
                  품명
                </th>
                <th
                  :style="
                    `border-left: 2px solid ${statusMesboriTblUnsuk}; border-bottom: 2px solid ${statusMesboriTblUnsuk}; background-color: ${statusTempbgColorUnsuk}; color: ${statusTempFontUnsuk};`
                  "
                >
                  규격
                </th>
                <th
                  :style="
                    `border-left: 2px solid ${statusMesboriTblUnsuk}; border-bottom: 2px solid ${statusMesboriTblUnsuk}; background-color: ${statusTempbgColorUnsuk}; color: ${statusTempFontUnsuk};`
                  "
                >
                  수량(단위)
                </th>
                <!-- <th
                  :style="
                    `border-left: 2px solid ${statusMesboriTblUnsuk}; border-bottom: 2px solid ${statusMesboriTblUnsuk}; background-color: ${statusTempbgColorUnsuk}; color: ${statusTempFontUnsuk};`
                  "
                >
                  비고
                </th> -->
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="(materialStatus, index2) in items"
                :key="materialStatus.id"
              >
                <td
                  :style="
                    items.length - 1 == index2
                      ? `border-left: 0; border-bottom: 0; background-color: ${statusTableColorUnsuk}; color: ${statusDetailFontUnsuk};`
                      : `border-left: 0; border-bottom: 2px solid ${statusMesboriTblUnsuk};
                    background-color: ${statusTableColorUnsuk}; color: ${statusDetailFontUnsuk};`
                  "
                >
                  {{ materialStatus.create_time | formatDateNoHours }}
                </td>
                <td
                  class="text_left"
                  :style="
                    items.length - 1 == index2
                      ? `border-left: 2px solid ${statusMesboriTblUnsuk}; border-bottom: 0; background-color: ${statusTableColorUnsuk}; color: ${statusDetailFontUnsuk};`
                      : `border-left: 2px solid ${statusMesboriTblUnsuk}; border-bottom: 2px solid ${statusMesboriTblUnsuk};
                    background-color: ${statusTableColorUnsuk}; color: ${statusDetailFontUnsuk};`
                  "
                >
                  {{ materialStatus.company_name }}
                </td>
                <td
                  class="text_left"
                  :style="
                    items.length - 1 == index2
                      ? `border-left: 2px solid ${statusMesboriTblUnsuk}; border-bottom: 0; background-color: ${statusTableColorUnsuk}; color: ${statusDetailFontUnsuk};`
                      : `border-left: 2px solid ${statusMesboriTblUnsuk}; border-bottom: 2px solid ${statusMesboriTblUnsuk};
                    background-color: ${statusTableColorUnsuk}; color: ${statusDetailFontUnsuk};`
                  "
                >
                  {{ materialStatus.material_name }}
                </td>
                <td
                  :class="{ text_left: materialStatus.material_standard != '' }"
                  :style="
                    items.length - 1 == index2
                      ? `border-left: 2px solid ${statusMesboriTblUnsuk}; border-bottom: 0; background-color: ${statusTableColorUnsuk}; color: ${statusDetailFontUnsuk};`
                      : `border-left: 2px solid ${statusMesboriTblUnsuk}; border-bottom: 2px solid ${statusMesboriTblUnsuk};
                    background-color: ${statusTableColorUnsuk}; color: ${statusDetailFontUnsuk};`
                  "
                >
                  {{
                    materialStatus.material_standard == ''
                      ? '-'
                      : materialStatus.material_standard
                  }}
                </td>
                <td
                  class="text_right"
                  :style="
                    items.length - 1 == index2
                      ? `border-left: 2px solid ${statusMesboriTblUnsuk}; border-bottom: 0; background-color: ${statusTableColorUnsuk}; color: ${statusDetailFontUnsuk};`
                      : `border-left: 2px solid ${statusMesboriTblUnsuk}; border-bottom: 2px solid ${statusMesboriTblUnsuk};
                    background-color: ${statusTableColorUnsuk}; color: ${statusDetailFontUnsuk};`
                  "
                >
                  {{
                    `${$makeComma(materialStatus.quantity)}(${
                      findInfoFromId(unitCodes, materialStatus.incoming_unit_id)
                        .name
                    })`
                  }}
                </td>
                <!-- <td>
                  {{
                    findInfoFromId(material, materialStatus.material_id)
                      .detail == ''
                      ? '-'
                      : findInfoFromId(material, materialStatus.material_id)
                          .detail
                  }}
                </td> -->
              </tr>
            </tbody>
          </table>
        </swiper-slide>
      </swiper>
      <div class="slide_btn_wrap">
        <button
          type="button"
          class="button-prev"
          :style="`background-color: ${statusSlideBtnColorUnsuk};`"
        >
          <i
            class="fa fa-angle-left"
            :style="` color: ${statusSlideBtniColorUnsuk};`"
          ></i>
        </button>
        <button
          type="button"
          class="button-play-stop"
          :class="{ active: playStop }"
          @click.prevent="autoPlay()"
        ></button>
        <button
          type="button"
          class="button-next"
          :style="`background-color: ${statusSlideBtnColorUnsuk};`"
        >
          <i
            class="fa fa-angle-right"
            :style="` color: ${statusSlideBtniColorUnsuk};`"
          ></i>
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { yyyymmdd } from '@/utils/func';
import SWIPER_MIXIN from '@/mixins/status_board_swiper';
import FETCH_MIXIN from '@/mixins/fetch';

export default {
  mixins: [SWIPER_MIXIN, FETCH_MIXIN],
  data() {
    return {
      fullScreen: false,
      timerCount: 60,
      timerMax: 60,
      interval: null,
      timeInterval: null,

      statusArticleColorUnsuk: localStorage.getItem('statusArticleColorUnsuk'),
      statusMesborTblUnsuk: localStorage.getItem('statusMesborTblUnsuk'),
      statusMesboriTblUnsuk: localStorage.getItem('statusMesboriTblUnsuk'),
      statusTempbgColorUnsuk: localStorage.getItem('statusTempbgColorUnsuk'),
      statusTempFontUnsuk: localStorage.getItem('statusTempFontUnsuk'),
      statusTableColorUnsuk: localStorage.getItem('statusTableColorUnsuk'),
      statusDetailFontUnsuk: localStorage.getItem('statusDetailFontUnsuk'),
      statusBtnColorUnsuk: localStorage.getItem('statusBtnColorUnsuk'),
      statusBtnFontColorUnsuk: localStorage.getItem('statusBtnFontColorUnsuk'),
      statusSlideBtnColorUnsuk: localStorage.getItem(
        'statusSlideBtnColorUnsuk',
      ),
      statusSlideBtniColorUnsuk: localStorage.getItem(
        'statusSlideBtniColorUnsuk',
      ),
    };
  },
  computed: {
    ...mapGetters({
      materialStatusList: 'getMaterialStatusListFromMaterialStatus',
      material: 'getMaterial',
      unitCodes: 'getUnitCodes',
      materialInOutType: 'getMaterialInOutType',
      tabIndex: 'getOpenTabIndexFromStatusMonitorPage',
    }),
    filteredTodayMaterialStatus() {
      if (
        this.materialStatusList != undefined &&
        this.materialStatusList.length > 0
      ) {
        let clone = this.lodash.clonedeep(this.materialStatusList);
        let filterInOutType = clone.filter(x =>
          this.findInfoFromId(
            this.materialInOutType,
            x.material_in_out_type_id == 1,
          ),
        );
        let arr = [];
        if (this.fullScreen) {
          filterInOutType.forEach((element, index) => {
            if (index % 9 == 0) {
              arr.push(filterInOutType.slice(index, index + 9));
            }
          });
        } else {
          filterInOutType.forEach((element, index) => {
            if (index % 5 == 0) {
              arr.push(filterInOutType.slice(index, index + 5));
            }
          });
        }
        return arr;
      } else {
        return [];
      }
    },
  },
  methods: {
    FETCH_MATERIAL_STATUS_DAY() {
      let date = new Date();
      date = yyyymmdd(date);
      this.$store
        .dispatch('FETCH_MATERIAL_STATUS_DAY', {
          start: date,
          end: date,
        })
        .then(() => {})
        .catch(() => {
          this.openOneButtonModal(
            '로드 중 오류',
            '원자재입고현황 정보를 불러오는 중 오류가 발생했습니다.',
          );
        })
        .finally(() => {});
    },
    async refreshData() {
      await this.FETCH_MATERIAL_STATUS_DAY();
    },
  },
  created() {
    this.FETCH('FETCH_MATERIAL_WITH_COMPANY', '원자재');
    this.FETCH('FETCH_UNIT', '단위');
    this.FETCH('FETCH_MATERIAL_IN_OUT_TYPE', '자재 입출고 유형');
    this.FETCH_MATERIAL_STATUS_DAY();
    this.timerCount = this.timerMax;

    this.interval = setInterval(async () => {
      this.timerCount = this.timerMax;

      if (this.tabIndex == 0) {
        await this.FETCH_MATERIAL_STATUS_DAY();
      }
      clearInterval(this.timeInterval);
      this.timeInterval = setInterval(() => {
        this.timerCount -= 1;
      }, 1000);
    }, this.timerMax * 10000);

    this.timeInterval = setInterval(() => {
      this.timerCount -= 1;
    }, 1000);
  },
};
</script>

<style lang="scss" scoped></style>
