<template>
  <div
    class="article"
    :class="{ active: fullScreen == true }"
    :style="`background-color: ${statusArticleColorUnsuk}`"
  >
    <div class="tbl_wrap">
      <div class="tbl_option">
        <h5 class="title" :style="`color: ${statusTempFontUnsuk};`">
          작업 현황
        </h5>
        <button
          class="btn_sub1 refresh_btn"
          :style="
            `background-color: ${statusBtnColorUnsuk} color: ${statusBtnFontColorUnsuk}`
          "
        >
          재조회
        </button>
        <button
          class="btn_sub1"
          @click="fullScreenFn"
          :style="
            `background-color: ${statusBtnColorUnsuk} color: ${statusBtnFontColorUnsuk}`
          "
        >
          {{ fullScreen ? '닫기' : '전체화면' }}
        </button>
      </div>
      <swiper
        ref="mySwiperRef"
        class="swiper-container"
        :options="swiperOption"
        :style="`border: 2px solid ${statusMesborTblUnsuk}`"
      >
        <swiper-slide
          class="mes_tbl_wrap"
          v-for="(items, index) in filteredTodayWorkStatusList"
          :key="index"
        >
          <table class="mes_tbl">
            <thead>
              <tr>
                <th
                  :style="
                    `border-bottom: 2px solid ${statusMesboriTblUnsuk}; background-color: ${statusTempbgColorUnsuk}; color: ${statusTempFontUnsuk};`
                  "
                >
                  작업일자
                </th>
                <th
                  :style="
                    `border-left: 2px solid ${statusMesboriTblUnsuk}; border-bottom: 2px solid ${statusMesboriTblUnsuk}; background-color: ${statusTempbgColorUnsuk}; color: ${statusTempFontUnsuk};`
                  "
                >
                  원료명
                </th>
                <th
                  :style="
                    `border-left: 2px solid ${statusMesboriTblUnsuk}; border-bottom: 2px solid ${statusMesboriTblUnsuk}; background-color: ${statusTempbgColorUnsuk}; color: ${statusTempFontUnsuk};`
                  "
                >
                  배합비율
                </th>
                <th
                  :style="
                    `border-left: 2px solid ${statusMesboriTblUnsuk}; border-bottom: 2px solid ${statusMesboriTblUnsuk}; background-color: ${statusTempbgColorUnsuk}; color: ${statusTempFontUnsuk};`
                  "
                >
                  투입량
                </th>
                <th
                  :style="
                    `border-left: 2px solid ${statusMesboriTblUnsuk}; border-bottom: 2px solid ${statusMesboriTblUnsuk}; background-color: ${statusTempbgColorUnsuk}; color: ${statusTempFontUnsuk};`
                  "
                >
                  탱크번호
                </th>
                <!-- <th
                  :style="
                    `border-left: 2px solid ${statusMesboriTblUnsuk}; border-bottom: 2px solid ${statusMesboriTblUnsuk}; background-color: ${statusTempbgColorUnsuk}; color: ${statusTempFontUnsuk};`
                  "
                >
                  비고
                </th> -->
              </tr>
            </thead>
            <tbody>
              <tr v-for="item in items" :key="item.id">
                <td>{{ item.create_time | formatDateNoHours }}</td>
                <td class="text_left">
                  {{ findInfoFromId(materials, item.material_id).name }}
                </td>
                <td class="text_right">
                  {{
                    $makeComma(
                      $makeNumberFixed2(
                        $decimalMul(
                          $decimalDiv(
                            item.quantity,
                            items
                              .map(x => x.quantity)
                              .reduce((a, b) => $decimalAdd(a, b), 0),
                          ),
                          100,
                        ),
                      ),
                    )
                  }}
                  %
                </td>
                <td class="text_right">
                  {{
                    $makeComma(
                      $makeNumberFixed2($decimalDiv(item.quantity, item.count)),
                    )
                  }}
                  ({{ findInfoFromId(units, item.unit_id).name }})
                </td>
                <td>{{ item.tank_number }}</td>
                <!-- <td>{{ '-' }}</td> -->
              </tr>
            </tbody>
          </table>
        </swiper-slide>
      </swiper>
      <div class="slide_btn_wrap">
        <button
          type="button"
          class="button-prev"
          :style="`background-color: ${statusSlideBtnColorUnsuk};`"
        >
          <i
            class="fa fa-angle-left"
            :style="` color: ${statusSlideBtniColorUnsuk};`"
          ></i>
        </button>
        <button
          type="button"
          class="button-play-stop"
          :class="{ active: playStop }"
          @click.prevent="autoPlay()"
        ></button>
        <button
          type="button"
          class="button-next"
          :style="`background-color: ${statusSlideBtnColorUnsuk};`"
        >
          <i
            class="fa fa-angle-right"
            :style="` color: ${statusSlideBtniColorUnsuk};`"
          ></i>
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { yyyymmdd } from '@/utils/func';
import SWIPER_MIXIN from '@/mixins/status_board_swiper';
import FETCH_MIXIN from '@/mixins/fetch';

export default {
  mixins: [SWIPER_MIXIN, FETCH_MIXIN],
  data() {
    return {
      fullScreen: false,
      timerCount: 60,
      timerMax: 60,
      interval: null,
      timeInterval: null,

      statusArticleColorUnsuk: localStorage.getItem('statusArticleColorUnsuk'),
      statusMesborTblUnsuk: localStorage.getItem('statusMesborTblUnsuk'),
      statusMesboriTblUnsuk: localStorage.getItem('statusMesboriTblUnsuk'),
      statusTempbgColorUnsuk: localStorage.getItem('statusTempbgColorUnsuk'),
      statusTempFontUnsuk: localStorage.getItem('statusTempFontUnsuk'),
      statusTableColorUnsuk: localStorage.getItem('statusTableColorUnsuk'),
      statusDetailFontUnsuk: localStorage.getItem('statusDetailFontUnsuk'),
      statusBtnColorUnsuk: localStorage.getItem('statusBtnColorUnsuk'),
      statusBtnFontColorUnsuk: localStorage.getItem('statusBtnFontColorUnsuk'),
      statusSlideBtnColorUnsuk: localStorage.getItem(
        'statusSlideBtnColorUnsuk',
      ),
      statusSlideBtniColorUnsuk: localStorage.getItem(
        'statusSlideBtniColorUnsuk',
      ),
    };
  },
  computed: {
    ...mapGetters({
      products: 'getProduct',
      materials: 'getMaterial',
      sales: 'getSales',
      units: 'getUnitCodes',
      companys: 'getCompany',
      tabIndex: 'getOpenTabIndexFromStatusMonitorPage',
      plc_temperature_logs: 'getTemperatureSensorLog',
      processing_lots: 'getProcessingLot',
      lot_tank: 'getLotTank',
    }),
    filteredTodayWorkStatusList() {
      if (
        this.processing_lots != undefined &&
        this.processing_lots.length > 0
      ) {
        let clone = this.lodash.clonedeep(this.processing_lots);
        clone = clone.map(x => {
          x.listTemp = [];
          const lot_tank = this.lot_tank.find(y => y.lot_id == x.id);
          if (lot_tank != undefined) {
            let count = 0;
            count += lot_tank.tank1 ? 1 : 0;
            count += lot_tank.tank2 ? 1 : 0;
            count += lot_tank.tank3 ? 1 : 0;
            count += lot_tank.tank4 ? 1 : 0;
            if (lot_tank.tank1) {
              x.listTemp.push(
                this.lodash.clonedeep(x.lot_resource).map(y => {
                  y.tank_number = 1;
                  y.count = count;
                  return y;
                }),
              );
            }
            if (lot_tank.tank2) {
              x.listTemp.push(
                this.lodash.clonedeep(x.lot_resource).map(y => {
                  y.tank_number = 2;
                  y.count = count;
                  return y;
                }),
              );
            }
            if (lot_tank.tank3) {
              x.listTemp.push(
                this.lodash.clonedeep(x.lot_resource).map(y => {
                  y.tank_number = 3;
                  y.count = count;
                  return y;
                }),
              );
            }
            if (lot_tank.tank4) {
              x.listTemp.push(
                this.lodash.clonedeep(x.lot_resource).map(y => {
                  y.tank_number = 4;
                  y.count = count;
                  return y;
                }),
              );
            }
          }
          return x.listTemp;
        });
        const reduceClone = clone.reduce((a, b) => a.concat(b));

        return reduceClone;
      } else {
        return [];
      }
    },
  },
  methods: {
    async FETCH_SALES() {
      this.showSpinner();
      let end_date = new Date();
      end_date = end_date.setDate(end_date.getDate() + 365);

      await this.$store
        .dispatch('FETCH_SALES', {
          start_date: '2001-01-01',
          end_date: yyyymmdd(new Date(end_date)),
        })
        .then(() => {})
        .catch(error => {
          console.log(error);
          this.openOneButtonModal(
            '로드 중 오류',
            '리스트를 불러오는 중 오류발생',
          );
        })
        .finally(() => {
          this.hideSpinner();
        });
    },
  },
  async created() {
    if (this.products.length < 1) {
      await this.FETCH('FETCH_PRODUCT_WITH_COMPANY', '제품');
    }
    if (this.materials.length < 1) {
      await this.FETCH('FETCH_MATERIAL_WITH_COMPANY', '원자재');
    }
    if (this.companys.length < 1) {
      await this.FETCH('FETCH_COMPANY', '거래처');
    }
    if (this.units.length < 1) {
      await this.FETCH('FETCH_UNIT', '단위');
    }
    if (this.lot_tank.length < 1) {
      await this.FETCH('FETCH_LOT_TANK', '투입 탱크');
    }
    await this.FETCH('FETCH_LOT_PROCESSING', '진행중 공정');

    this.timerCount = this.timerMax;

    this.interval = setInterval(async () => {
      this.timerCount = this.timerMax;

      if (this.tabIndex == 1) {
        await this.FETCH('FETCH_LOT_PROCESSING', '진행중 공정');
      }
      clearInterval(this.timeInterval);
      this.timeInterval = setInterval(() => {
        this.timerCount -= 1;
      }, 1000);
    }, this.timerMax * 10000);

    this.timeInterval = setInterval(() => {
      this.timerCount -= 1;
    }, 1000);
  },
};
</script>

<style lang="scss" scoped></style>
