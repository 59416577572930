var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"status_board_unsuk",class:{
    plc_monitor: _vm.tabIndex != 4,
    work_status: _vm.tabIndex == 1,
    color_setting: _vm.tabIndex == 4,
  },attrs:{"id":"contents"}},[_c('div',{staticClass:"contents_head"},[_c('h2',[_vm._v(_vm._s(_vm.$getPageTitle(_vm.$route.path)))]),(_vm.$screen.width < 1280)?_c('div',{staticClass:"aside"},[_c('aside-selectric',{attrs:{"id":'tabletAsideSelectric',"watch":_vm.tabIndex,"commit":'setOpenTabIndexToStatusMonitorPage',"options":[
          { title: '입고 현황', name: 'input_status' },
          { title: '작업 현황', name: 'work_status' },
          { title: '생산 현황', name: 'production_status' },
          { title: '출고 현황', name: 'output_status' },
          { title: '현황판 색 설정', name: 'color_setting' } ]}})],1):_vm._e(),_c('div',{staticClass:"management_btn_group"},[_c('div',[_c('span',{staticClass:"add_favorite",class:{ on: _vm.isFavorOn },on:{"click":_vm.AddFavorite}},[_vm._v("즐겨찾기 추가")]),_c('button',{staticClass:"btn_admin",on:{"click":function($event){return _vm.CloseThisPage()}}},[_vm._v("닫기")])])])]),_c('div',{staticClass:"section"},[(_vm.$screen.width >= 1280)?_c('div',{staticClass:"aside"},[_c('ul',[_c('li',{class:{ active: _vm.tabIndex == 0 }},[_c('a',{on:{"click":function($event){return _vm.SetOpenTabIndex(0)}}},[_vm._v("입고 현황")])]),_c('li',{class:{ active: _vm.tabIndex == 1 }},[_c('a',{on:{"click":function($event){return _vm.SetOpenTabIndex(1)}}},[_vm._v("작업 현황")])]),_c('li',{class:{ active: _vm.tabIndex == 2 }},[_c('a',{on:{"click":function($event){return _vm.SetOpenTabIndex(2)}}},[_vm._v("생산 현황")])]),_c('li',{class:{ active: _vm.tabIndex == 3 }},[_c('a',{on:{"click":function($event){return _vm.SetOpenTabIndex(3)}}},[_vm._v("출고 현황")])]),_c('li',{class:{ active: _vm.tabIndex == 4 }},[_c('a',{on:{"click":function($event){return _vm.SetOpenTabIndex(4)}}},[_vm._v("현황판 색 설정")])])])]):_vm._e(),(_vm.tabIndex == 0)?_c('input-status-form'):_vm._e(),(_vm.tabIndex == 1)?_c('work-status-form'):_vm._e(),(_vm.tabIndex == 2)?_c('production-status-form'):_vm._e(),(_vm.tabIndex == 3)?_c('output-status-form'):_vm._e(),(_vm.tabIndex == 4)?_c('status-color-picker-form'):_vm._e()],1)])}
var staticRenderFns = []

export { render, staticRenderFns }